@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;700&display=swap);
html, body, #root {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
}
html *, html:after, html:before {
  box-sizing: inherit;
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  flex: 1 0 auto;
  margin-top:100px;
}

.empty-div {
 height: auto !important;
}

#nested-list-subheader {
  z-index:2;
} 

